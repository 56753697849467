.bv-list-header {
  background: rgb(220, 220, 220);
  font-weight: 600;

  .padding {
    padding: 10px 20px;
  }
}

.bv-list-row:nth-of-type(odd) {
  background: rgb(240, 240, 240);
}
