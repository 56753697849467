.__popup {
  .__popup-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--popupModalBackground);
    z-index: 8000;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .__popup-popup {
    text-align: left;
    border: var(--popupBorder);
    background: var(--popupBackground);
    max-height: 95vh;
    overflow: auto;

    .__popup-header {
      display: flex;
      justify-content: flex-start;
    }

    .__popup-title {
      font-size: 1.2rem;
      flex-grow: 1;
      padding: calc(var(--popupPadding) / 1.5);
      margin-bottom: 0;
    }

    .__popup-popup-content {
      padding-left: var(--popupPadding);
      padding-right: var(--popupPadding);
      padding-bottom: var(--popupPadding);
    }

    .__popup-popup-content.nopadding {
      padding: 0;
    }

    @media screen and (max-width: var(--breakSmall)) {
      width: 100%;
    }
  }

  .__popup-popup.tiny {
    width: 400px;
  }

  .__popup-popup.small {
    width: 40%;
  }

  .__popup-popup.medium {
    width: 55%;
  }

  .__popup-popup.large {
    width: 85%;
  }

  .__popup-popup.fullwidth {
    width: 95%;
  }

  .__popup-popup.fullscreen {
    width: 100%;
    height: 100vh;
    max-height: 100vh;
  }

  .__popup-close-button-wrapper {
    padding: calc(var(--popupPadding) / 1.5);

    .__popup-close-button {
      cursor: pointer;
    }

    .__popup-close-button:hover {
      color: var(--primaryColor);
    }
  }
}
