.__cc-bauvorhaben-bv-layout {
  display: flex;
  justify-content: flex-start;

  @media screen and (max-width: $breakSmall) {
    flex-direction: column;
  }

  .__cc-bauvorhaben-bv-tabs {
    margin-left: var(--padding);
    margin-right: var(--padding);
    margin-bottom: var(--padding);
    min-width: var(--ccBvTabsWidth);
    max-width: var(--ccBvTabsWidth);

    @media screen and (max-width: $breakSmall) {
      margin-left: auto;
      margin-right: auto;
    }

    .no-margin.__box {
      margin-bottom: 10px;
    }

    .__cc-bauvorhaben-bv-tabs-tab {
      width: 100%;
      border-bottom: var(--boxBorder);
      cursor: pointer;
      font-size: 0.9rem;
      display: flex;
      justify-content: flex-start;
      background: var(--ccBvTabsBackground);
      color: var(--ccBvTabsColor);

      .fa {
        color: var(--ccBvTabsIconColor);
      }

      .__cc-bauvorhaben-bv-tabs-tab-border {
        text-align: left;
        width: 100%;
        padding: var(--ccBvTabsPadding);
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
      }

      .__ptplatform-icon {
        margin-right: 6px;
      }
    }

    .__cc-bauvorhaben-bv-tabs-tab:hover {
      background-color: var(--ccBvTabsHoverBackground);
      color: var(--ccBvTabsHoverColor);
    }

    .selected.__cc-bauvorhaben-bv-tabs-tab {
      background-color: var(--ccBvTabsSelectedBackground);
      color: var(--ccBvTabsSelectedColor);

      .__cc-bauvorhaben-bv-tabs-tab-border {
        border-left-color: var(--ccBvTabsSelectedBorderColor);
      }
    }

    .disabled.__cc-bauvorhaben-bv-tabs-tab {
      opacity: 0.3;
      cursor: default;
    }

    .disabled.__cc-bauvorhaben-bv-tabs-tab:hover {
      opacity: 0.3;
      cursor: default;
      background: var(--ccBvTabsBackground);
      color: var(--ccBvTabsColor);
    }
  }

  .__cc-bauvorhaben-content {
    padding-right: calc(var(--padding) / 2);
    flex-grow: 1;

    @media screen and (max-width: $breakSmall) {
      padding-left: calc(var(--padding) / 2);
    }

    h2.__cc-bauvorhaben-tab-tabs-title {
      background-color: var(--ccBvTabTabsSelectedColor);
      color: white;
      padding: 8px 20px;
      text-align: center;
      font-size: 1rem;
      font-weight: 600;
    }
  }
}

.__cc-bauvorhaben-aboveheader {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;

  position: fixed;
  width: calc(100% - var(--ccNavigationWidth));
  background-color: var(--bodyBackground);

  @media screen and (max-width: $breakSmall) {
    position: static;
    width: 100%;
    flex-direction: column;
  }

  button,
  select {
    margin-bottom: 0;
  }

  .__cc-bauvorhaben-aboveheader-left {
    display: flex;
    justify-content: flex-start;

    @media screen and (max-width: $breakSmall) {
      flex-direction: column;
      justify-content: space-between;
      gap: 10px;
    }

    .__cc-bauvorhaben-aboveheader-backbutton {
      font-weight: 600;

      a {
        width: var(--ccBvTabsWidth);

        @media screen and (max-width: $breakSmall) {
          width: 100%;
        }
      }
    }

    .__cc-bauvorhaben-jump-to-bauvorhaben {
      padding-left: 10px;

      @media screen and (max-width: $breakSmall) {
        padding-left: 0;
      }

      select {
        appearance: initial;
        height: 34px;
        font-size: 0.8rem;
      }
    }

    .__loading-box {
      padding: 5px;

      .__loading-box-icon {
        img {
          height: 20px !important;
          width: 20px !important;
        }
      }
    }
  }

  .__cc-bauvorhaben-aboveheader-buttons {
    @media screen and (max-width: $breakSmall) {
      padding-top: 10px;
    }
  }
}

.__cc-bauvorhaben-below-aboveheader {
  padding-top: 50px;
}

.__cc-bauvorhaben-header-box {
  background-color: white;
  border-bottom: var(--boxBorder);
  padding: var(--padding);
  margin: 0 var(--padding) var(--padding) var(--padding);

  .__admin-header-title {
    flex-grow: 1;
  }

  .__admin-header-buttons {
    padding-left: var(--padding);
  }
}

.__cc-bauvorhaben-header-box-content {
  display: flex;
  justify-content: space-between;
}

.__cc-bauvorhaben-header-box-main {
  display: flex;
  justify-content: flex-start;
  flex: 1;

  .__cc-bauvorhaben-image {
    margin-right: 20px;
  }

  h1 {
    margin-bottom: 0;
  }
}

.__cc-bauvorhaben-header-labels {
  margin-top: 4px;
}

.__cc-bauvorhaben-header-address {
  margin-top: -5px;

  @media screen and (max-width: $breakSmall) {
    font-size: 0.8rem;
  }
}

.__cc-bauvorhaben-header-termine {
  border-left: 1px solid var(--boxBorderColor);
  border-right: 1px solid var(--boxBorderColor);
  padding-left: var(--padding);
  padding-right: var(--padding);
  min-width: 100px;
  text-align: center;

  @media screen and (max-width: $breakSmall) {
    display: none;
  }

  .__cc-bauvorhaben-header-termine-termin {
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 120%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .termin-text {
      color: var(--headingColor);
    }
  }

  .__cc-bauvorhaben-header-termine-termin:nth-of-type(2) {
    border-top: 1px solid var(--boxBorderColor);
  }
}

.__cc-bauvorhaben-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 106.25px;
  padding: 20px 0;
  text-align: center;
  font-size: 0.9rem;
  background-color: rgb(250, 250, 250);
  background-size: cover;
  border: 1px solid rgb(230, 230, 230);
}

.__cc-bauvorhaben-buttons {
  display: flex;
  justify-content: flex-end;
}

.__cc-bauvorhaben-tab-tabs {
  font-size: 0.9rem;

  .selected.__tabs-tab {
    background-color: var(--ccBvTabTabsSelectedColor);
    border-color: var(--ccBvTabTabsSelectedColor);
  }
}

.__cc-bauvorhaben-clients-content {
  ul {
    li {
      padding: 10px 20px 7px 20px;
    }

    li:nth-of-type(odd) {
      background: var(--adminlistRowBackgroundOdd);
    }

    li:nth-of-type(even) {
      background: var(--adminlistRowBackgroundEven);
    }
  }
}

.__cc-bauvorhaben-labels {
  display: flex;
  justify-content: flex-start;
  gap: 3px;

  .__cc-bauvorhaben-labels-label {
    border-radius: 6px;
    padding: 3px 8px;
    font-size: 0.8rem;
    font-weight: 600;
  }

  .label-type-status {
    background-color: rgb(39, 155, 187);
    color: rgb(255, 255, 255);
  }

  .label-type-kategorie {
    background-color: rgb(170, 0, 0);
    color: rgb(255, 255, 255);
  }

  .label-type-energiestandard {
    background-color: rgb(178, 241, 60);
    color: rgb(81, 110, 26);
  }
}

.cc-bv-status-link-to-tab {
  .fa {
    font-size: 0.8em;
  }
}

.__bv-detail-percent {
  .__bv-detail-percent-title {
    color: var(--primaryColor);
    font-weight: 500;
  }

  .__bv-detail-percent-view {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .__bv-detail-percent-view-bar {
      flex-grow: 1;
    }

    .__bv-detail-percent-view-label {
      width: 70px;
      text-align: right;

      font-size: 0.8em;
      position: relative;
      top: -5px;
    }
  }
}

.bv-fortschritt-htp-status {
  font-weight: 600;
  font-size: 0.8rem;
  text-align: center;
}
