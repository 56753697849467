.__admin-emailtemplates-list-line {
  padding: 5px 0;
  border-bottom: 1px dashed var(--grayColor);
}

.__admin-emailtemplates-list-line:last-of-type {
  border-bottom: none;
}

.__admin-emailtemplates-placeholders {
  ul {
    font-size: 0.9rem;

    li {
      button:hover {
        code {
          background-color: var(--hoverColor);
        }
      }
    }
  }
}
