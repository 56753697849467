.__cc-wrapper {
  main {
    display: flex;
    justify-content: flex-start;
    min-height: calc(100vh - var(--topbarHeight));

    @media screen and (max-width: $breakSmall) {
      flex-direction: column;
    }
  }

  .__cc-navigation {
    position: fixed;
    left: 0;
    top: var(--topbarHeight);
    width: var(--ccNavigationWidth);
    height: calc(100vh - var(--topbarHeight));
    background: var(--ccNavigationBackground);
    color: var(--ccNavigationColor);
    border-right: var(--ccNavigationBorder);

    @media screen and (max-width: $breakSmall) {
      position: static;
      width: 100%;
      height: initial;
      border-right: none;
      border-bottom: var(--ccNavigationBorder);

      ul {
        display: flex;
        justify-content: center;
      }
    }

    .__-navigation-version {
      text-align: center;
      font-size: 0.8rem;
      font-weight: bold;
      position: fixed;
      bottom: 20px;
      width: var(--ccNavigationWidth);

      @media screen and (max-width: $breakSmall) {
        display: none;
      }
    }

    .__cc-navigation-item {
      display: block;
      width: var(--ccNavigationWidth);
      height: var(--ccNavigationWidth);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: var(--ccNavigationColor);
      border-bottom: var(--ccNavigationItemBorder);
    }

    .__cc-navigation-item:hover {
      background: var(--ccNavigationItemHoverBackground) !important;
      color: var(--ccNavigationItemHoverColor);
    }

    .selected.__cc-navigation-item {
      background: var(--ccNavigationItemSelectedBackground);
      color: var(--ccNavigationItemSelectedColor);
    }

    .__cc-navigation-item-content {
      color: inherit;
    }

    .__cc-navigation-item-icon {
      color: inherit;
      text-align: center;
      font-size: 1.1rem;

      .fa {
        color: inherit;
      }

      .fa-home {
        font-size: 1.4rem;
      }
    }

    .__cc-navigation-item-label {
      font-size: 0.7rem;
      letter-spacing: -0.5px;
      text-align: center;
      color: inherit;
      position: relative;
      top: -5px;
    }
  }

  .__cc-content {
    flex-grow: 1;
    padding-left: var(--ccNavigationWidth);

    @media screen and (max-width: $breakSmall) {
      padding-left: 0;
    }
  }
}

.__mst-syncinfo {
  text-align: right;
  color: var(--primaryColor);
  font-size: 0.75rem;
  margin-bottom: 20px;
  padding: 0 10px;

  button {
    color: var(--primaryColor);
    cursor: pointer;
    text-decoration: none;
    font-weight: 600;
  }

  button:hover {
    text-decoration: underline;
  }
}

.button-goto-tab {
  font-size: 1rem;
}
