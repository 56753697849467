.__bv-status-termine {
  h4 {
    margin: 0;
    background-color: var(--hoverColor);
    color: var(--primaryColor);
    padding: 5px 0;
    text-align: center;
    font-size: 1rem;
  }

  .__bv-status-termine-termin {
    text-align: center;
  }
}

.__bv-status-details-separator {
  hr {
    margin: 10px 0;
  }
}

.__bvdetail {
  clear: both;
  padding: 5px 0;

  .__bvdetail-icon {
    width: 30px;
    float: left;

    .fa {
      font-size: 1.2em;
    }

    .__bvdetail-yesno-icon {
      display: inline-block;
      text-align: center;
      width: 20px;
    }

    .__bvdetail-trafficlight-icon {
      display: inline-block;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: #999;
    }

    .__bvdetail-trafficlight-icon.type-red {
      background-color: var(--statusRed);
    }

    .__bvdetail-trafficlight-icon.type-yellow {
      background-color: var(--statusOrange);
    }

    .__bvdetail-trafficlight-icon.type-green {
      background-color: var(--statusGreen);
    }

    button.is-editable {
      cursor: pointer;
    }
  }

  .__bvdetail-label {
    padding-left: 30px;
    font-weight: 600;
    font-size: 0.9em;
    line-height: 18px;
  }
}

.__progresslistbox-content {
  padding-top: 10px;
}

.__percentbar {
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-weight: 600;
}

.status-red.__percentbar {
  background-color: var(--statusRed);
  color: white;
}

.status-orange.__percentbar {
  background-color: var(--statusOrange);
  color: white;
}

.status-green.__percentbar {
  background-color: var(--statusGreen);
  color: white;
}

.__bv-status-fortschritt,
.__bv-status-waermepumpe {
  h3 {
    margin-bottom: 15px;
  }
}
