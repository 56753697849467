.__admin-wrapper {
  position: relative;
}

.__admin-nav {
  width: var(--adminNavWidth);
  position: sticky;
  top: var(--topbarHeight);
  float: left;
  height: calc(100vh - var(--topbarHeight));
  padding: var(--padding);
  border-right: var(--adminNavBorder);

  .__admin-nav-title {
    font-weight: bold;
    padding: 10px var(--padding);
  }

  ul {
    li {
      a,
      button {
        display: block;
        width: 100%;
        padding: var(--adminNavItemPadding);
        background-color: var(--adminNavItemBackground);
        color: var(--adminNavItemColor);
      }

      a:hover,
      button:hover {
        background-color: var(--adminNavItemHoverBackground);
        color: var(--adminNavItemHoverColor);
      }
    }

    li.selected {
      a,
      button {
        background-color: var(--adminNavItemHoverBackground);
        color: var(--adminNavItemHoverColor);
      }
    }
  }
}

.__admin-header {
  margin-bottom: calc(var(--padding));

  .__admin-header-content {
    display: flex;
    justify-content: space-between;
  }

  .__admin-header-title {
  }

  h1 {
    margin-top: -5px;
  }

  .__admin-header-buttons {
    .__admin-header-buttons-item {
      margin-left: 5px;
    }
  }
}

.__admin-content {
  padding-left: calc(var(--adminNavWidth) + var(--padding));
  padding-top: var(--padding);
  padding-right: var(--padding);
}

.__admin-backbutton {
  font-size: 0.85rem;
  text-decoration: none;
}

.__admin-backbutton:hover {
  text-decoration: underline;
}

.__admin-total-found {
  text-align: center;
  margin-bottom: 20px;
}
