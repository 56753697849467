.__cc-bauvorhaben-clients-list {
  //   border-top: 1px solid var(--boxBorderColor);
  //   border-left: 1px solid var(--boxBorderColor);
  //   border-right: 1px solid var(--boxBorderColor);

  li {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid var(--boxBorderColor);
    padding: 4px 8px;

    div:first-of-type {
      width: 20px;
      color: var(--headingColor);
    }

    div:nth-of-type(2) {
      flex: 1;

      .__cc-bauvorhaben-clients-list-clientname {
        width: 100%;
        color: var(--headingColor);
      }

      .__cc-bauvorhaben-clients-list-clientemail {
        width: 100%;
        font-size: 0.9rem;
        margin-top: -5px;
      }
    }

    div:nth-of-type(3) {
      button {
        color: var(--headingColor);
        cursor: pointer;
        height: 100%;
        padding-left: 6px;
        padding-right: 6px;
      }

      button:hover {
        background-color: var(--headingColor);
        color: white;
      }
    }
  }
}

.button-add-client {
  font-size: 0.9rem;
  color: var(--headingColor);
  font-weight: 600;
  margin-top: 10px;
  padding: 8px;
  text-align: center;
  width: 100%;
  cursor: pointer;
}

.button-add-client:hover {
  background: var(--hoverColor);
}

.__cc-bauvorhaben-clients-content {
  ul {
    margin-top: 5px;
    margin-bottom: 5px;

    li {
      padding: 4px 20px !important;
    }
  }
}
