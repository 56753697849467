.__cc-bauvorhaben-hausanschluesse {
  .not-used.hausanschluesse-type-box {
    opacity: 0.3;
  }

  .finished.hausanschluesse-type-box {
    .__box {
      background-color: var(--statusShallowGreen);
    }
  }

  .box-padding {
    padding: 10px 20px;
    border-top: 1px solid var(--boxBorderColor);
  }

  h4 {
    padding: 10px 20px;
    margin: 0;
    text-align: center;
    color: var(--primaryColor);
    font-size: 1.1rem;
    font-weight: 500;
  }

  button.hausanschluss-button-edit-termin {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
    font-weight: 600;
    cursor: pointer;
    color: inherit;
  }

  button.hausanschluss-button-edit-termin:hover {
    text-decoration: underline;
  }

  button.hausanschluss-button-edit-termin.view-home {
    cursor: default;
  }

  button.hausanschluss-button-edit-termin.view-home:hover {
    text-decoration: none;
  }

  .hausanschluesse-status {
    display: block;
    width: 100%;
    text-align: center;
    padding: 3px 0px;
    line-height: 120%;
  }

  button.hausanschluesse-status {
    cursor: pointer;
  }

  button.hausanschluesse-status:hover {
    text-decoration: underline;
  }

  button.hausanschluesse-status.view-home {
    cursor: default;
  }

  button.hausanschluesse-status.view-home:hover {
    text-decoration: none;
  }
}

.hausanschluesse-settings-popup {
  button.hausanschluesse-settings-popup-toggle {
    cursor: pointer;
    color: var(--primaryColor);
  }

  button.hausanschluesse-settings-popup-toggle:hover {
    text-decoration: underline;
  }
}
