.paymentplan-priceoverview-line {
  display: flex;
  justify-content: space-between;
  gap: 20px;

  .paymentplan-priceoverview-line-label {
    flex: 1;
  }

  .paymentplan-priceoverview-line-value {
    flex: 1;
    text-align: right;
    font-weight: 600;
  }

  .is-number {
    font-family: "Courier New", Courier, monospace;
    font-size: 0.9em;
  }
}

.success-text {
  .paymentplan-priceoverview-line-value {
    color: var(--statusGreen) !important;
  }
}

.warning-text {
  .paymentplan-priceoverview-line-value {
    color: var(--statusOrange) !important;
  }
}

.error-text {
  .paymentplan-priceoverview-line-value {
    color: var(--statusRed) !important;
  }
}

.paymentplan-priceoverview-separator {
  margin: 5px 0;
}

.paymentplan-installments {
  padding-bottom: 20px;

  .__adminlist-head {
    .__adminlist-cell {
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 0.8rem;
    }
  }
  .__adminlist-row {
    padding: 0;

    .__adminlist-cell {
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 0.8rem;

      button.edit-button {
        padding: 8px;
        margin: 0;
        cursor: pointer;
        display: block;
        width: 100%;
        height: 100%;
        text-align: left;
        line-height: normal;
      }

      button.edit-button:hover {
        background-color: var(--hoverColor);
      }

      button.edit-button.view-home {
        cursor: default;
      }

      button.edit-button.view-home:hover {
        background-color: inherit;
      }

      button.checkbox-button {
        padding: 8px;
        margin: 0;
        display: block;
        width: 100%;
        height: 100%;
        text-align: center;
        cursor: pointer;
        color: var(--primaryColor);
      }

      button.checkbox-button:hover {
        background-color: var(--hoverColor);
      }

      button.checkbox-button.view-home {
        cursor: default;
      }

      button.checkbox-button.view-home:hover {
        background-color: initial;
      }

      button.checkbox-button.checked {
        background-color: var(--statusShallowGreen);
        color: var(--statusGreen);
      }

      button.checkbox-button.not-checked.view-home {
        visibility: hidden;
      }

      .label {
        font-size: 0.5rem;
        font-weight: 600;
        padding: 4px 8px;
      }
    }

    .__adminlist-cell.nopadding {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .paymentplan-installments-footer {
    .__adminlist-cell {
      font-weight: bold;
    }
  }
}
