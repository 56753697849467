.__fatalerror {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  background: var(--errorFatalErrorModalBackground);

  .__fatalerror-popup {
    background: var(--errorFatalErrorPopupBackground);
    color: var(--errorFatalErrorPopupColor);
    padding: 40px;
    width: 70%;
    margin: auto;

    @media screen and (max-width: var(--breakSmall)) {
      width: 90%;
      display: block;
    }

    @media screen and (min-width: var(--breakSmall)) {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    .pretitle {
      color: red;
      font-size: 1rem;
      font-weight: 600;
    }

    h1 {
      font-size: 1.4rem;
      font-weight: 600;
    }

    .__fatalerror-popup-text {
      p:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
