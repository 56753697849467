//.__cc-bauvorhaben-aufgaben {}

.__cc-bauvorhaben-aufgaben-buckets-wrapper {
  max-width: calc(100vw - var(--ccNavigationWidth) - var(--ccBvTabsWidth) - 100px);
  overflow-x: auto;
  padding-bottom: var(--padding);
}

.__cc-bauvorhaben-aufgaben-buckets {
  display: flex;
  gap: 10px;
  justify-content: flex-start;

  .__cc-bauvorhaben-aufgaben-buckets-bucket {
    width: var(--ccBvTasksBucketWidth);

    .__cc-bauvorhaben-aufgaben-buckets-bucket-name {
      //   border: 1px solid var(--hoverColor);
      //   border-radius: var(--boxBorderRadius);
      //   background-color: var(--boxBackground);
      width: var(--ccBvTasksBucketWidth);
      padding: 10px;
      font-weight: 600;
      text-align: center;
      color: var(--headingColor);
      margin-bottom: 10px;
    }
  }
}

.__cc-bauvorhaben-aufgaben-task {
  width: 100%;
  border: 1px solid var(--boxBorderColor);
  border-radius: var(--boxBorderRadius);
  background-color: var(--boxBackground);
  margin-bottom: 10px;
  font-size: inherit;
  line-height: inherit;
  text-align: left;
  cursor: pointer;
  transition-duration: 0.5s;

  .__cc-bauvorhaben-aufgaben-content {
    padding: 20px 20px 5px 20px;
  }

  .__cc-bauvorhaben-aufgaben-task-checklist {
    font-size: 0.7rem;

    .__cc-bauvorhaben-aufgaben-task-checklist-list {
      padding: 10px 0;

      li {
        overflow: hidden;
        white-space: nowrap; /* Don't forget this one */
        text-overflow: ellipsis;
        max-width: calc(var(--ccBvTasksBucketWidth) - 40px);
      }
    }
  }

  .__cc-bauvorhaben-aufgaben-task-icons {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    padding: 0 20px 12px 20px;
  }

  .__cc-bauvorhaben-aufgaben-task-bottomline {
    border-top: 1px solid var(--boxBorderColor);
    padding: 8px 20px;
    font-size: 0.85rem;
    display: flex;
    justify-content: space-between;

    .__cc-bauvorhaben-aufgaben-task-duedate {
      line-height: 30px;
      font-size: 0.8rem;
    }

    .is-overdue.__cc-bauvorhaben-aufgaben-task-duedate {
      color: var(--statusRed);
    }

    .__cc-bauvorhaben-aufgaben-task-assignments {
      display: flex;
      justify-content: flex-start;
      gap: 3px;

      .__cc-bauvorhaben-aufgaben-task-assignments-assignment-more {
        .__avatar-without-image {
          background-color: transparent;
        }
      }
    }
  }
}

.progress-icon {
  color: rgb(70, 70, 140);
}

.exclamation-icon {
  color: var(--statusRed);
  font-weight: 800;
}

.__cc-bauvorhaben-aufgaben-task:hover {
  //border-color: rgb(190, 190, 190);
  //background-color: var(--hoverColor);
  transform: scale(1.01);
  transition-duration: 0.5s;
  -webkit-box-shadow: 2px 2px 14px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 2px 14px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 2px 14px -9px rgba(0, 0, 0, 0.75);

  // .__cc-bauvorhaben-aufgaben-task-bottomline {
  //   //border-color: rgb(190, 190, 190);
  // }
}

.__cc-bauvorhaben-aufgaben-popup {
  .__popup-popup-content {
    padding-top: 10px;
  }

  .__cc-bauvorhaben-aufgaben-popup-topinfoline {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .__cc-bauvorhaben-aufgaben-popup-status,
    .__cc-bauvorhaben-aufgaben-popup-priority,
    .__cc-bauvorhaben-aufgaben-popup-duedate {
      flex-grow: 1;
    }
  }

  .__cc-bauvorhaben-aufgaben-popup-checklist {
    font-size: 0.9rem;

    .__cc-bauvorhaben-aufgaben-popup-checklist-list {
      padding: 0 0 10px 0;

      li {
        overflow: hidden;
        white-space: nowrap; /* Don't forget this one */
        text-overflow: ellipsis;
        max-width: calc(var(--ccBvTasksBucketWidth) - 40px);
      }
    }
  }
}

.__cc-bauvorhaben-aufgaben-tasksplanrefreshing {
  text-align: center;
}

.__cc-bauvorhaben-aufgaben-syncinfoline {
  text-align: center;
  font-size: 0.8rem;
  color: var(--headingColor);
  padding: 5px 0 10px 0;

  button {
    cursor: pointer;
    text-decoration: underline;
    color: inherit;
  }

  button:hover {
    text-decoration: none;
  }
}

.__cc-bauvorhaben-aufgaben-comments-posts {
  max-height: 60vh;
  overflow-y: auto;
}
