.__login-box {
  border: var(--loginBoxBorder);
  background: var(--loginBoxBackground);
  padding: var(--loginBoxPadding);
}

.__login-logo {
  text-align: center;
  padding: calc(var(--padding) * 2);

  img {
    max-height: 60px;
  }
}

.__login-password-forgotten-link {
  padding: 20px 0;
  text-align: center;
}

.__login-with-signup {
  padding-top: calc(var(--padding) / 2);
}
