.__box {
  border: var(--boxBorder);
  border-radius: var(--boxBorderRadius);
  background: var(--boxBackground);

  padding: var(--boxPadding);
  margin-bottom: var(--boxMargin);
}

.small-box.__box {
  padding: var(--boxSmallPadding);
  margin-bottom: var(--boxSmallMargin);
}

.no-padding.__box {
  padding: 0;
}

.no-margin.__box {
  margin: 0;
}

.clickable.__box {
  cursor: pointer;
}

.clickable.__box:hover {
  background-color: var(--hoverColor);
}

.__box-padding {
  padding: var(--boxPadding);
}

hr.__box-line {
  margin: 0;
  border-color: var(--boxBorderColor);
}
