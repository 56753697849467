.__filebrowser-breadcrumbs {
  //padding: 0 10px 10px 10px;
  // border: 1px solid $boxBorderColor;
  // background-color: $boxBackground;
  // margin-bottom: 10px;
  font-size: 0.9rem;
  margin-bottom: 10px;

  .__filebrowser-breadcrumbs-breadcrumb {
    cursor: pointer;
    color: var(--headingColor);
  }

  .__filebrowser-breadcrumbs-breadcrumb:hover {
    text-decoration: underline;
  }

  .disabled.__filebrowser-breadcrumbs-breadcrumb {
    cursor: default;
    color: inherit;
  }

  .disabled.__filebrowser-breadcrumbs-breadcrumb:hover {
    text-decoration: none;
  }

  .__filebrowser-breadcrumbs-separator {
    padding: 0 6px;
  }
}

.__filebrowser-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 10px;

  @media screen and (max-width: $breakSmall) {
    flex-direction: column-reverse;
  }

  .__filebrowser-list {
    flex-grow: 6;
  }

  .__filebrowser-fileinfo {
    flex-grow: 4;
  }
}

.__filebrowser-list {
  border: 1px solid var(--boxBorderColor);
  background-color: white;
}

.__filebrowser-list-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  width: 100%;
  cursor: pointer;
  padding: 8px 10px;
  border-bottom: 1px solid var(--boxBorderColor);

  .__filebrowser-list-icon {
    margin-right: 10px;
  }

  .__filebrowser-list-item-name {
    flex-grow: 1;
  }

  .__filebrowser-list-item-file-arrow {
    visibility: hidden;
  }
}

.selected.__filebrowser-list-item {
  background-color: var(--hoverColor);

  .__filebrowser-list-item-file-arrow {
    visibility: visible;
  }
}

.__filebrowser-list-item:hover {
  background-color: var(--hoverColor);
}

.__filebrowser-list-icon {
  height: 26px;
  width: auto;
}

.__filebrowser-list-icon-type-back {
  height: 16px;
  margin-left: 1px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.__filebrowser-fileinfo {
  max-width: 600px;

  .__filebrowser-fileinfo-close-button {
    text-align: right;

    button {
      cursor: pointer;
    }

    button:hover {
      color: var(--headingColor);
    }
  }

  .__filebrowser-fileinfo-preview {
    margin-bottom: var(--padding);

    .__filebrowser-fileinfo-preview-icon {
      img {
        max-width: 100%;
        max-height: 400px;
      }
    }

    .__filebrowser-fileinfo-preview-icon {
      text-align: center;

      .__filebrowser-list-icon {
        width: 100px;
        height: auto;
      }
    }
  }

  .__filebrowser-fileinfo-filesystem-dates {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    font-size: 0.9rem;
    line-height: 130%;

    .__box {
      flex-grow: 1;
    }

    h4 {
      margin-bottom: 0;
      font-size: 0.9rem;
    }
  }

  .__filebrowser-fileinfo-actions {
    display: flex;
    justify-content: flex-start;
    gap: 3px;
  }
}

.__filebrowser-infoline {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  padding: 5px 10px;
  text-align: center;

  .__filebrowser-infoline-left {
    color: var(--headingColor);

    button {
      cursor: pointer;
      text-decoration: underline;
      color: inherit;
    }

    button:hover {
      text-decoration: none;
    }
  }

  // .__filebrowser-infoline-middle {
  // }

  // .__filebrowser-infoline-right {
  // }
}
