.__cc-bauvorhaben-list-tabs {
  font-size: 0.8rem;
}

.__cc-bauvorhaben-list-item {
  display: block;
  border: var(--boxBorder);
  border-radius: var(--boxBorderRadius);
  padding: var(--padding);
  margin-bottom: 10px;
  background-color: var(--boxBackground);
  cursor: pointer;
  transition-duration: 0.5s;
}

.__cc-bauvorhaben-list-item:hover {
  transform: scale(1.01);
  transition-duration: 0.5s;
  -webkit-box-shadow: 2px 2px 14px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 2px 14px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 2px 14px -9px rgba(0, 0, 0, 0.75);
}

.__cc-bauvorhaben-list-item-name-and-image {
  display: flex;
  justify-content: flex-start;

  .__cc-bauvorhaben-image {
    width: 140px;
    height: 87.5px;
  }
}

.__cc-bauvorhaben-list-item-name {
  font-weight: 600;
  color: var(--headingColor);
  font-size: 0.98rem;
  padding-left: 20px;
}

.__cc-bauvorhaben-list-item-address {
  font-size: 0.9rem;
  padding-left: 20px;
}

.__cc-bauvorhaben-list-item-labels {
  padding-top: 8px;
  padding-left: 20px;

  .__cc-bauvorhaben-labels-label {
    font-size: 0.75rem !important;
  }
}
