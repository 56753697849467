button.__admin-userrole-toggle-permission {
  display: block;
  width: 100%;
  padding: 5px 8px;
  margin-bottom: 2px;
  cursor: pointer;
  text-align: left;
  line-height: initial;
}

button.__admin-userrole-toggle-permission:hover {
  opacity: 0.8;
}

.no-edit-permission.__admin-userrole-toggle-permission {
  cursor: default;
}

.no-edit-permission.__admin-userrole-toggle-permission:hover {
  opacity: 1;
}

.checked.__admin-userrole-toggle-permission {
  background: var(--statusLightGreen);

  .icon-checked {
    display: inline-block;
  }

  .icon-unchecked {
    display: none;
  }
}

.unchecked.__admin-userrole-toggle-permission {
  background: var(--statusLightRed);

  .icon-checked {
    display: none;
  }

  .icon-unchecked {
    display: inline-block;
  }
}
