.__dropdownbutton {
  .__dropdownbutton-button-wrapper {
    display: inline-block;
  }

  .__dropdownbutton-menu {
    position: absolute;
    top: 0;
    left: 0;
    width: var(--dropdownbuttonMenuWidth);
    min-width: var(--dropdownbuttonMenuWidth);
    border: var(--dropdownMenuBorder);
    background: var(--dropdownMenuBackground);

    @media screen and (max-width: var(--breakSmall)) {
      width: 100%;
    }

    ul {
      li {
        border-bottom: var(--dropdownbuttonMenuItemBorder);

        a,
        button {
          cursor: pointer;
          display: block;
          width: 100%;
          text-align: left;
          padding: var(--dropdownbuttonItemPadding);
          background: var(--dropdownbuttonMenuItemBackground);
          color: var(--dropdownbuttonMenuItemColor);
        }

        a:hover,
        button:hover {
          background: var(--dropdownbuttonMenuItemHoverBackground);
          color: var(--dropdownbuttonMenuItemHoverColor);
        }
      }

      li:last-of-type {
        border-bottom: none;
      }
    }
  }
}
