.__myaccount-avatar-upload {
  .__myaccount-avatar-upload-cell-upload {
  }

  .__myaccount-avatar-upload-cell-avatar {
    text-align: right;
  }
}

.__myaccount-avatar-upload.vertical {
  .__myaccount-avatar-upload-cell-upload {
    text-align: center;
    order: 2;
  }

  .__myaccount-avatar-upload-cell-avatar {
    text-align: center;
    order: 1;
  }
}

.__myaccount-avatar-delete-button {
  margin-top: 10px;
  color: #666;
  font-size: 0.9rem;
}
