#x_jSanity_hideInPlanner {
  display: none;
}

.__conversation {
  padding-right: var(--padding);

  .__conversation-posts-post {
    display: flex;
    justify-content: flex-start;
    //border: 1px solid red;

    .__conversation-posts-post-frame {
      width: var(--conversationPostMaxWidth);
      display: flex;
      justify-content: flex-start;
      gap: 5px;
      //border: 1px solid green;
    }

    .__conversation-posts-post-content-wrapper {
      //border: 1px solid navy;
      width: 100%;

      .__conversation-posts-post-infoline {
        width: inherit;
        display: flex;
        justify-content: space-between;
        font-size: 0.75rem;
        color: var(--grayColor);
        padding: 5px 5px;

        .__conversation-posts-post-infoline-author {
          flex: 1;
          color: inherit;
        }

        .__conversation-posts-post-infoline-date {
          color: inherit;
        }
      }

      .__conversation-posts-post-content {
        flex: 1;
        border: var(--conversationPostBorder);
        border-radius: var(--conversationPostBorderRadius);
        background: var(--conversationPostBackground);
        padding: var(--conversationPostPadding);
        margin-bottom: var(--conversationPostMargin);

        .__conversation-posts-post-body {
          font-size: 0.9rem;
        }
      }
    }
  }

  .own.__conversation-posts-post {
    justify-content: flex-end;
  }
}

.__conversation-newcommentform-noteamsconnection {
  text-align: center;
  padding: 30px;
}

.__conversation-newcommentform {
  border-bottom: 1px solid var(--boxBorderColor);
  margin-bottom: 20px;
  padding-bottom: 10px;

  .__conversation-newcommentform-title {
    font-weight: 600;
    font-size: 0.9rem;
    text-align: center;
    color: var(--headingColor);
    margin-bottom: 8px;
  }

  .__conversation-newcommentform-form {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    .__conversation-newcommentform-form-textarea {
      flex-grow: 1;

      textarea {
        margin-bottom: 0;
      }
    }

    .__conversation-newcommentform-form-buttons {
      padding-top: 5px;
      text-align: right;
    }
  }
}
